<template>
    <b-modal
      v-model="data.showModal"
      size="md"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          Edit Scale - {{ device.device_description }}
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <table class="table table-google">
          <thead>
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ID</td>
              <td>{{ device.id }}</td>
            </tr>

            <tr>
              <td>Description</td>
              <td>
                <b-form-input
                  v-model="device.device_description"
                  placeholder="Description"
                  size="sm"
                  autocomplete="off"
                  trim
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td>Model</td>
              <td>
                <b-form-input
                  v-model="device.device_model"
                  placeholder="Model"
                  size="sm"
                  autocomplete="off"
                  trim
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td>Token</td>
              <td>
                <b-input-group size="sm">
                  <b-form-input
                    v-model="device.device_token"
                    readonly
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      title="Generate new token"
                      v-b-tooltip.hover
                      @click="generateNewToken"
                      :disabled="isLoading"
                    >
                      <i class="fas fa-redo"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="onSave"
            >
              Save
            </b-btn>
          </div>
          <div class="flex gap-2">
            <b-btn
              size="sm"
              variant="danger"
              :disabled="isLoading"
              @click="deleteDevice"
            >
              Delete
            </b-btn>

            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
export default {
  name: 'DeviceEditor',
  components: {},
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (this.isLoading) return false;
      if (this.device.device_token.length !== 32) return false;
      if (this.device.device_description.length === 0) return false;
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {},
      device: {
        id: null,
        device_description: '',
        device_token: '',
      },
    };
  },
  methods: {
    fetchDevice() {
      this.loadingCount++;
      this.$http
        .get(`/scale/devices/${this.data.deviceId}`)
        .then((res) => {
          this.device = res.body.device;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch device: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    generateNewToken() {
      this.loadingCount++;
      this.$http
        .get('/scale/generateDeviceToken')
        .then((res) => {
          this.device.device_token = res.body.token;
        })
        .catch((err) => {
          this.$toast.error(`Failed to generate new token: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    updateDevice() {
      this.loadingCount++;
      this.$http
        .put(`/scale/devices/${this.data.deviceId}`)
        .send({ changes: this.device })
        .then(() => {
          this.$emit('updated');
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update device: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteDevice() {
      if (!confirm('Do you really wish to delete this device?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/scale/devices/${this.data.deviceId}`)
        .then(() => {
          this.$emit('updated');
          this.onClose();
        })
        .catch(() => {
          this.$toast.error('Failed to delete device: Device has active weight records');
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchDevice();
    },
    onSave() {
      this.updateDevice();
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
